import { Notice, QueuePosition } from "bouncer-waitingroom";

export class WaitingRoomHandler {
    // dynamic waiting room elements
    private positionInfoElement: HTMLParagraphElement;
    private noticeListElement: HTMLUListElement;

    // views
    private enterButton: HTMLButtonElement;


    constructor() {
        this.positionInfoElement = document.querySelector("#position-info") as HTMLParagraphElement;
        this.noticeListElement = document.querySelector("#notice-list") as HTMLUListElement;

        this.enterButton = document.querySelector("#enter") as HTMLButtonElement;

        this.registerHandlers();
    }


    private registerHandlers(): void {
        document.addEventListener("bouncer:queuePositionUpdated", (e) => this.updateQueuePosition(e.detail));
        document.addEventListener("bouncer:noticesUpdated", (e) => this.updateNotices(e.detail));
    }

    private updateQueuePosition(queuePosition: QueuePosition | null): void {
        if (queuePosition === null) {
            this.enterButton.disabled = true;
            this.positionInfoElement.innerText = "Du bist im Vorwarteraum. Deine Position wird Dir später angezeigt!"
            return;
        }

        if (queuePosition.position === 1) {
            console.info("one ticket ahead")

            this.enterButton.disabled = true;
            this.positionInfoElement.innerText = "Ein User ist vor Dir!"

            return;
        }

        if (queuePosition.position <= 0) {
            console.info("its this tickets turn")

            this.enterButton.disabled = false;
            this.positionInfoElement.innerText = "Du bist jetzt dran!"

            return;
        }

        this.enterButton.disabled = true;
        this.positionInfoElement.innerText = `${queuePosition.position} User sind vor Dir!`;
    }

    private updateNotices(notices: Array<Notice>): void {
        let updatedNoticeList = "";
        notices.forEach(n => updatedNoticeList += `<li class="list-group-item">${n.message}</li>`);

        if (notices.length === 0) {
            this.noticeListElement.style.display = "none";
        } else {
            this.noticeListElement.style.display = "block";
        }

        this.noticeListElement.innerHTML = updatedNoticeList;
    }
}
